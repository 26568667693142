<template>
  <v-container>
    <v-card>
      <v-toolbar color="primary">
        <v-toolbar-title>
          <v-icon large>
          mdi-account-plus
        </v-icon>
        แก้ไขลูกค้า
        </v-toolbar-title>    
      </v-toolbar>
      <!-- <v-card-title>
        <v-icon large>
          mdi-account-plus
        </v-icon>
        แก้ไขลูกค้า
      </v-card-title> -->
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
              <v-row>
                <v-col class="py-0">
                  <v-text-field
                    v-model.trim="customer.name"
                    color="secondary"
                    name="ชื่อ"
                    hint="ชื่อ"
                    label="ชื่อ"
                    :rules="[(v) => !!v || 'Name is required']"
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col class="py-0">
                  <v-text-field
                    v-model.trim="customer.ba_id"
                    color="secondary"
                    name="รหัสลูกค้า (BA)"
                    hint="รหัสลูกค้า (BA)"
                    label="รหัสลูกค้า (BA)"
                    type="number"
                    :maxLen="12"
                    :counter="12"
                    :rules="[
                      (v) => {
                        if (v)
                          return (v.length == 12) || 'BA must have 12 characters';
                        else return true;
                      },
                    ]"
                  ></v-text-field>
                </v-col>
              </v-row> -->
              <v-row>
                <v-col class="py-0">
                  <v-text-field
                    v-model.trim="customer.address"
                    color="secondary"
                    name="ที่อยู่"
                    hint="ที่อยู่"
                    label="ที่อยู่"
                    :counter=50
                    :rules="[
                      (v) => !!v || 'Address is required',
                      (v) => {
                        if (v)
                          return (
                            v.length <= 50 ||
                            'A maximum of 50 characters is allowed'
                          );
                        else return true;
                      },
                    ]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0" cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                  <v-autocomplete
                    :items="provinces"
                    v-model="selectProvince"
                    name="province"
                    item-text="name_in_thai"
                    item-value="id"
                    label="จังหวัด"
                    color="secondary"
                    required
                    :rules="[(v) => !!v || 'Province is required']"
                    item-color="grey"
                    @change="getProvinceDistricts()"
                  ></v-autocomplete>
                </v-col>
                <v-col class="py-0" cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                  <v-autocomplete
                    :items="districts"
                    v-model="selectDistrict"
                    name="district"
                    item-text="name_in_thai"
                    item-value="id"
                    label="อำเภอ"
                    color="secondary"
                    required
                    :rules="[(v) => !!v || 'District is required']"
                    item-color="grey"
                    @change="getDistrictSubdistricts()"
                  ></v-autocomplete>
                </v-col>
                <v-col class="py-0" cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                  <v-autocomplete
                    :items="subdistricts"
                    v-model="customer.subdistrict_id"
                    name="subdistrict"
                    item-text="name_in_thai"
                    item-value="id"
                    label="ตำบล"
                    color="secondary"
                    required
                    :rules="[(v) => !!v || 'Subdistrict is required']"
                    item-color="grey"
                    @change="subdistrictChange()"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <vue-tel-input-vuetify
                    required
                    label="โทรศัพท์"
                    placeholder="โทรศัพท์"
                    clearable
                    :maxLen="15"
                    defaultCountry="TH"
                    v-bind:onlyCountries="['TH']"
                    mode="international"
                    v-model="customer.phone"
                  ></vue-tel-input-vuetify>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <v-select
                    :items="customerCategories"
                    v-model="customer.customerCategory_id"
                    name="customerCategory"
                    item-text="name"
                    item-value="id"
                    label="ประเภทลูกค้า"
                    color="secondary"
                    required
                    :rules="[(v) => !!v || 'Customer category is required']"
                    item-color="grey"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <v-autocomplete
                    :items="officeSectors"
                    v-model="selectOfficeSector"
                    name="officeSector"
                    item-text="name"
                    item-value="id"
                    label="ส่วนบริการลูกค้า"
                    color="secondary"
                    required
                    :rules="[(v) => !!v || 'Required']"
                    item-color="grey"
                    @change="getOfficeSectorOfficeCentres()"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <v-autocomplete
                    :items="officeCentres"
                    v-model="customer.officeCentre_id"
                    name="customerOfficeCentre"
                    item-text="name"
                    item-value="id"
                    label="ศูนย์บริการลูกค้า"
                    color="secondary"
                    required
                    :rules="[(v) => !!v || 'Required']"
                    item-color="grey"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <v-text-field
                    v-model.trim="customer.tax_id"
                    color="secondary"
                    name="Tax ID"
                    hint="Tax ID"
                    label="Tax ID"
                    :counter="13"
                    :rules="[
                      (v) => {
                        if (v)
                          return (
                            v.length == 13 ||
                            'Tax ID must have 13 characters'
                          );
                        else return true;
                      },
                    ]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <v-textarea
                    v-model.trim="customer.remark"
                    color="secondary"
                    name="หมายเหตุ"
                    hint="หมายเหตุ"
                    label="หมายเหตุ"
                    rows="2"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="py-0" cols="12" xs="12" sm="12" md="12" lg="8" xl="8">
              <v-row>
                <v-col>
                  <v-card>
                    <v-card-text>
                      <gmap-map
                        :center="center"
                        :zoom="zoom"
                        style="width: 100%; height: 540px"
                        ref="mapRef"
                        @click="handleMapClick"
                      >
                        <GmapMarker
                          :position="marker.position"
                          :clickable="true"
                          :draggable="true"
                          @drag="handleMarkerDrag"
                          @click="panToMarker"
                        />
                      </gmap-map>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0" cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                  <v-text-field
                    v-model="customer.latitude"
                    color="secondary"
                    name="Latitude"
                    hint="Latitude"
                    label="Latitude"
                    type="number"
                    :rules="[
                      (v) => (v >= -90 && v <= 90) || 'Invalid latitude',
                    ]"
                    clearable
                    @click:clear="clearCustomerPosition"
                    @input="inputCustomerPosition"
                  ></v-text-field>
                </v-col>
                <v-col class="py-0" cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                  <v-text-field
                    v-model="customer.longitude"
                    color="secondary"
                    name="Longitude"
                    hint="Longitude"
                    label="Longitude"
                    type="number"
                    :rules="[
                      (v) => (v >= -180 && v <= 180) || 'Invalid longitude',
                    ]"
                    @click:clear="clearCustomerPosition"
                    @input="inputCustomerPosition"
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  lg="4"
                  xl="4"
                  align="center"
                >
                  <v-btn
                    color="blue"
                    class="body-2 font-weight-bold"
                    outlined
                    @click="geolocate()"
                  >
                    <v-icon>
                      mdi-crosshairs-gps
                    </v-icon>
                    ใช้ตำแหน่งปัจจุบัน</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          class="body-2 font-weight-bold"
          outlined
          @click="save()"
        >
          <v-icon> mdi-content-save </v-icon>save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";

moment.locale("th");

export default {
  name: "EditCustomer",
  props: {
    // eslint-disable-next-line vue/require-default-prop
    id: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      provinces: [],
      districts: [],
      subdistricts: [],
      officeSectors: [],
      officeCentres: [],
      selectOfficeSector: null,
      selectOfficeCentre: null,
      selectProvince: null,
      selectDistrict: null,
      selectSubDistrict: null,
      customerCategories: [],
      customer: {
        name: null,
        address: null,
        subdistrict_id: null,
        phone: null,
        customerCategory: null,
        latitude: null,
        longitude: null,
      },
      marker: {},
      // marker: { position: { lat: 13.72, lng: 100.92 } },
      center: { lat: 13.72, lng: 100.92 },
      zoom: 4,
    };
  },
  watch: {},
  mounted() {},
  created() {
    this.getCustomerData();
  },
  methods: {
    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.marker.position = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.customer.latitude = position.coords.latitude;
        this.customer.longitude = position.coords.longitude;
        this.panToMarker();
        this.zoom = 18;
      });
    },

    //sets the position of marker when dragged
    handleMarkerDrag(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      this.customer.latitude = this.marker.position.lat;
      this.customer.longitude = this.marker.position.lng;
    },

    //Moves the map view port to marker
    panToMarker() {
      this.$refs.mapRef.panTo(this.marker.position);
      this.zoom = 15;
    },
    panToPosition(position) {
      this.$refs.mapRef.panTo(position);
      this.zoom = 15;
    },
    //Moves the marker to click position on the map
    handleMapClick(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      this.customer.latitude = this.marker.position.lat;
      this.customer.longitude = this.marker.position.lng;
      // console.log(e);
    },
    clearCustomerPosition() {
      this.customer.latitude = null;
      this.customer.longitude = null;
    },
    getCustomerData() {
      axios
        .get("/customer/" + this.id)
        .then((response) => {
          this.customer = response.data;
        })
        .then(async () => {
          await this.getProvinces();
          this.selectProvince = this.customer.subdistrict.district.province.id;
          await this.getProvinceDistricts();
          this.selectDistrict = this.customer.subdistrict.district.id;
          await this.getDistrictSubdistricts();
          this.selectSubDistrict = this.customer.subdistrict_id;
          await this.getOfficeSectors();
          this.selectOfficeSector = this.customer.officeCentre.officeSector.id;
          await this.getOfficeSectorOfficeCentres();
          this.selectOfficeCentre = this.customer.officeCentre_id;
          this.inputCustomerPosition();
          this.getCustomerCategories();

        });
    },
    getCustomerCategories() {
      axios.get("/customer-category").then((response) => {
        this.customerCategories = response.data;
      });
    },
    getOfficeSectors() {
      return new Promise((resolve, reject) => {
        axios
          .get("/office-sector")
          .then((response) => {
            this.officeSectors = response.data;
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getOfficeSectorOfficeCentres() {
      return new Promise((resolve, reject) => {
        axios
          .get("/office-sector/" + this.selectOfficeSector + "/office-centre")
          .then((response) => {
            this.officeCentres = response.data;
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getProvinces() {
      return new Promise((resolve, reject) => {
        axios
          .get("/province")
          .then((response) => {
            this.provinces = response.data;
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getProvinceDistricts() {
      return new Promise((resolve, reject) => {
        axios
          .get("/province/" + this.selectProvince + "/district")
          .then((response) => {
            this.districts = response.data;
            this.subdistricts = [];
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getDistrictSubdistricts() {
      return new Promise((resolve, reject) => {
        axios
          .get("/district/" + this.selectDistrict + "/sub-district")
          .then((response) => {
            this.subdistricts = response.data;
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    subdistrictChange() {
      let customerSubdistrict = this.subdistricts.find(
        (subdistrict) => subdistrict.id == this.customer.subdistrict_id
      );
      if (this.customer.latitude === null && this.customer.longitude === null) {
        this.panToPosition({
          lat: Number(customerSubdistrict.latitude),
          lng: Number(customerSubdistrict.longitude),
        });
      }
    },
    inputCustomerPosition() {
      if (this.customer.latitude !== null && this.customer.longitude !== null) {
        this.marker.position = {
          lat: Number(this.customer.latitude),
          lng: Number(this.customer.longitude),
        };
        this.panToMarker();
      } else {
        this.marker = {};
        if (this.customer.subdistrict_id !== null) {
          let customerSubdistrict = this.subdistricts.find(
            (subdistrict) => subdistrict.id == this.customer.subdistrict_id
          );
          this.panToPosition({
            lat: Number(customerSubdistrict.latitude),
            lng: Number(customerSubdistrict.longitude),
          });
        } else {
          this.$refs.mapRef.panTo(this.center);
          this.zoom = 4;
        }
      }
    },
    save() {
      if (this.$refs.form.validate()) {
        delete this.customer.id;
        delete this.customer.customerCategory;
        delete this.customer.subdistrict;
        delete this.customer.officeCentre;
        axios
          .put("/customer/" + this.id, this.customer)
          .then((response) => {
            this.createCustomerResponse = response.data;
            if (this.createCustomerResponse.status === "success") {
              this.$router.push("/customer/" + this.id + "/show");
            }
          })
          .catch(function() {});
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
